<template>
    <div id="jobs">
    <HeadOrders 
    :PathPageFrom="$t('Home')"
    :PathPageCenter="$t('Jobs')"
    :PathPageTo="$t('JobsDetails')" />
    <div class="container">
    <JobDetails />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Jobs Page',
  components: {
    HeadOrders: defineAsyncComponent( () => import('@/components/Global/HeadOrders.vue') ),
    JobDetails: defineAsyncComponent( () => import('@/components/Jobs/JobDetails.vue') ),
  },
  }
  </script>
  
  

  